import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "message-form row" }
const _hoisted_2 = { class: "col-12 col-md-5 col-lg-4 col-xl-3 q-py-md preview" }
const _hoisted_3 = { class: "row justify-end q-gutter-sm" }
const _hoisted_4 = { class: "flex justify-left items-center q-gutter-x-xs" }

import QForm from 'quasar/src/components/form/QForm.js';import useQuasar from 'quasar/src/composables/use-quasar.js';;
import type { QTableProps } from "quasar";
import { Component, computed, ComputedRef, Ref, ref, toRefs } from "vue";

import { MessageDeliveryMetadata } from "@/api/pushnotifications/messagedelivery/typedefs";
import {
  fetchMessageTarget,
  fetchMessageTargets,
} from "@/api/pushnotifications/pushmessage/target";
import {
  isExistingPushMessage,
  PushMessage,
} from "@/api/pushnotifications/pushmessage/typedefs";
import { fetchTags } from "@/api/tags/list";
import { ValidationStatus } from "@/api/target/typedefs";
import RejectedUsersDialog from "@/components/pushmessages/RejectedUsersDialog.vue";
import { useMessageForm } from "@/components/pushmessages/useMessageForm";
import { deliveryTypeOptions } from "@/components/pushmessages/useMessageForm";
import ConversionReport from "@/components/shared/conversionreport/ConversionReport.vue";
import { MessageTypeEnum } from "@/components/shared/conversionreport/useConversionReport";
import ImageSelect from "@/components/shared/imageselect/ImageSelect.vue";
import ReadonlyField from "@/components/shared/ReadonlyField.vue";
import TagSelect from "@/components/shared/tags/TagSelect.vue";
import TargetCard from "@/components/shared/target/card/TargetCard.vue";
import { formatPretty } from "@/lib/format";
import { Pagination } from "@/lib/pagination";
import { ProductLine } from "@/lib/productLine";
import { isNotEmptyArray } from "@/lib/rules/arrayValidator";
import { getStatusColor } from "@/lib/status";
import { isValidMessageUrl } from "@/lib/url";

import DeliveryOptions from "./deliveryoptions/DeliveryOptionsInput.vue";
import MessageFormActions from "./MessageFormActions.vue";
import MessageFormVisualPreview from "./MessageFormVisualPreview.vue";
import { AllowedActionButtons } from "./useMessageFormActions";


export default /*@__PURE__*/_defineComponent({
  __name: 'MessageForm',
  props: {
    pushMessage: {},
    productLine: {},
    isLoading: { type: Boolean },
    isEditingActivePushMessage: { type: Boolean },
    isReadOnly: { type: Boolean },
    isClone: { type: Boolean },
    hasUnsavedChanges: { type: Boolean },
    hasUserTestDevice: { type: Boolean },
    validationStatus: {},
    allowedActionButtons: {},
    isAddImageButtonVisible: { type: Boolean },
    hasDryRunPermissions: { type: Boolean }
  },
  emits: ["pushMessageUpdate", "activate", "cancel", "clone", "delete", "preview", "reset", "submit", "submitForActiveMessage", "validate", "edit", "formValidationError"],
  setup(__props: any, { emit: __emit }) {

const quasar = useQuasar();

const messageDeliveryColumns: ComputedRef<QTableProps["columns"]> = computed(
  () => {
    const columns: QTableProps["columns"] = [
      {
        name: "deliveryTime",
        label: "Delivery Time",
        field: (row: MessageDeliveryMetadata) => row.deliveryTime,
        align: "left",
        sortable: true,
      },
      {
        name: "status",
        label: "Status",
        field: (row: MessageDeliveryMetadata) => row.status,
        align: "left",
        sortable: true,
      },
      {
        name: "sent",
        label: "Sent",
        align: "left",
        field: (row: MessageDeliveryMetadata) =>
          row.messagesSent === null ? "" : formatPretty(row.messagesSent),
        sortable: true,
      },
      {
        name: "affected",
        label: "Affected",
        align: "left",
        field: (row: MessageDeliveryMetadata) =>
          row.estimatedUsers === null ? "" : formatPretty(row.estimatedUsers),
        sortable: true,
      },
    ];

    if (hasRejectedUsers.value) {
      columns.push({
        name: "rejected",
        label: "Rejected users",
        align: "left",
        field: (row: MessageDeliveryMetadata) => {
          if (row.totalRejectedUsers === null) return "";

          return formatPretty(row.totalRejectedUsers);
        },
        sortable: true,
      });
    }

    return columns;
  }
);

const hasRejectedUsers = computed(() => {
  if (isExistingPushMessage(pushMessage.value)) {
    return !!pushMessage.value.deliveries?.find(
      (el: MessageDeliveryMetadata) => el.totalRejectedUsers !== null
    );
  }
  return false;
});

const $q = useQuasar();

const openRejectedUsersDialog = (payload: { [key: string]: number }) => {
  $q.dialog({
    component: RejectedUsersDialog,
    componentProps: {
      rejectedPerMessage: payload,
    },
  });
};

const deliveriesPagination: Partial<Pagination> = {
  sortBy: "deliveryTime",
  descending: false,
};

const props = __props;

const { pushMessage } = toRefs(props);

const emits = __emit;
const messageForm: Ref<QForm | null> = ref(null);
const isAdvancedOptionsVisible = ref(props.pushMessage.isDryRun);

const updatePushMessage = (changedData: Partial<PushMessage>) => {
  emits("pushMessageUpdate", changedData);
};

const updateCampaignId = (rawCampaignId: string | number | null) => {
  const campaignId = rawCampaignId !== "" ? Number(rawCampaignId) : null;
  updatePushMessage({ campaignId });
};

const onSubmit = async () => {
  const isValid = await messageForm.value?.validate(); // This should validate and show errors if not valid.
  if (!isValid) return;
  if (props.isEditingActivePushMessage) {
    return emits("submitForActiveMessage");
  }
  emits("submit");
};

const onReset = () => {
  messageForm.value?.resetValidation();
  emits("reset");
};

const validationErrorHandler = (component: Component) => {
  emits("formValidationError", component);
};

const {
  updatePushMessageDeliveryOptions,
  updateDeliveryOptionsType,
  selectedDeliveryType,
  isDisabled,
  isTargetEditingEnabled,
  isRedirectorStatsUrlVisible,
  campaignAMGUrl,
  fetchTargets,
  targetErrorMessage,
  formatMessageId,
  isConversionReportVisible,
} = useMessageForm({
  quasar,
  pushMessage: computed(() => props.pushMessage),
  updateMessageHandler: updatePushMessage,
  isEditingActivePushMessage: computed(() => props.isEditingActivePushMessage),
  isLoading: computed(() => props.isLoading),
  isReadonly: computed(() => props.isReadOnly),
  fetchTargetsHandler: fetchMessageTargets,
});

return (_ctx: any,_cache: any) => {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(MessageFormVisualPreview, {
        "product-line": _ctx.productLine,
        content: _unref(pushMessage).text,
        title: _unref(pushMessage).title,
        image: _unref(pushMessage).image
      }, null, 8, ["product-line", "content", "title", "image"])
    ]),
    _createVNode(_unref(QForm), {
      ref_key: "messageForm",
      ref: messageForm,
      class: "col-12 col-md-7 col-lg-7 col-xl-8 q-py-md form q-gutter-y-md q-px form-max-width",
      greedy: true,
      onSubmit: onSubmit,
      onReset: onReset,
      onValidationError: validationErrorHandler
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(MessageFormActions, {
            message: _unref(pushMessage),
            "is-loading": _ctx.isLoading,
            "is-editing-active-push-message": _ctx.isEditingActivePushMessage,
            "is-clone": _ctx.isClone,
            "has-unsaved-changes": _ctx.hasUnsavedChanges,
            "has-user-test-device": _ctx.hasUserTestDevice,
            "validation-status": _ctx.validationStatus,
            "has-dry-run-permissions": _ctx.hasDryRunPermissions,
            "allowed-action-buttons": _ctx.allowedActionButtons,
            onPreview: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('preview'))),
            onValidate: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('validate'))),
            onClone: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('clone'))),
            onActivate: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('activate'))),
            onCancel: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('cancel'))),
            onDelete: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('delete'))),
            onEdit: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('edit'))),
            onSave: onSubmit,
            onReset: onReset
          }, null, 8, ["message", "is-loading", "is-editing-active-push-message", "is-clone", "has-unsaved-changes", "has-user-test-device", "validation-status", "has-dry-run-permissions", "allowed-action-buttons"])
        ]),
        _createVNode(_component_q_card, null, {
          default: _withCtx(() => [
            (_unref(isExistingPushMessage)(_unref(pushMessage)))
              ? (_openBlock(), _createBlock(_component_q_card_section, {
                  key: 0,
                  class: "col row"
                }, {
                  default: _withCtx(() => [
                    _createVNode(ReadonlyField, {
                      "data-test": "pm.form.field.messageid",
                      class: "col",
                      label: "Message ID",
                      value: String(_unref(pushMessage).id)
                    }, null, 8, ["value"]),
                    _createVNode(ReadonlyField, {
                      "data-test": "pm.form.field.createdby",
                      class: "col",
                      label: "Created by",
                      value: _unref(pushMessage).createdBy
                    }, null, 8, ["value"]),
                    _createVNode(ReadonlyField, {
                      "data-test": "pm.form.field.createdat",
                      class: "col",
                      label: "Created at",
                      value: _unref(pushMessage).createdAt
                    }, null, 8, ["value"]),
                    _createVNode(ReadonlyField, {
                      "data-test": "pm.form.field.modifiedby",
                      class: "col",
                      label: "Modified by",
                      value: _unref(pushMessage).modifiedBy
                    }, null, 8, ["value"]),
                    _createVNode(ReadonlyField, {
                      "data-test": "pm.form.field.modifiedat",
                      class: "col",
                      label: "Modified at",
                      value: _unref(pushMessage).modifiedAt
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_q_card_section, {
              class: _normalizeClass(["q-col-gutter-sm", _unref(isRedirectorStatsUrlVisible) && 'q-pb-none'])
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_input, {
                  "model-value": _unref(pushMessage).name,
                  disable: _unref(isDisabled),
                  filled: "",
                  label: "Name",
                  hint: "Name of your message",
                  rules: [
              (val) =>
                (val && val.length > 0) || 'Message name cannot be empty',
            ],
                  "data-test": "pm.form.field.name",
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (
              updatePushMessage({
                name: $event ? String($event) : undefined,
              })
            ))
                }, null, 8, ["model-value", "disable", "rules"]),
                _createVNode(TagSelect, {
                  "model-value": _unref(pushMessage).tags,
                  disable: _unref(isDisabled) && !_ctx.isEditingActivePushMessage,
                  label: "Tags",
                  hint: "Tags help categorize your message",
                  rules: [_unref(isNotEmptyArray)],
                  "data-test": "pm.form.field.tags",
                  "fetch-options": _unref(fetchTags),
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (
              updatePushMessage({
                tags: $event,
              })
            ))
                }, null, 8, ["model-value", "disable", "rules", "fetch-options"])
              ]),
              _: 1
            }, 8, ["class"]),
            _createVNode(_component_q_card_section, { class: "row justify-end q-pt-none" }, {
              default: _withCtx(() => [
                (
              _unref(isExistingPushMessage)(_unref(pushMessage)) &&
              _unref(pushMessage).redirectorStatsUrl !== null
            )
                  ? (_openBlock(), _createBlock(_component_q_btn, {
                      key: 0,
                      "data-test": "pm.form.button.redirectorstatsurl",
                      outline: "",
                      color: "primary",
                      size: "sm",
                      label: "Check redirector statistics",
                      type: "a",
                      target: "_blank",
                      href: _unref(pushMessage).redirectorStatsUrl
                    }, null, 8, ["href"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_unref(isExistingPushMessage)(_unref(pushMessage)))
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_q_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_q_card_section, { class: "q-col-gutter-sm" }, {
                    default: _withCtx(() => [
                      _cache[17] || (_cache[17] = _createElementVNode("div", { class: "text-h6 q-mb-sm" }, "Message deliveries", -1)),
                      _createVNode(_component_q_table, {
                        "data-test": "pm.form.field.deliveries",
                        rows: _unref(pushMessage).deliveries,
                        columns: messageDeliveryColumns.value,
                        "no-data-label": "No message deliveries for this push notification",
                        "binary-state-sort": true,
                        pagination: deliveriesPagination,
                        flat: ""
                      }, {
                        "body-cell-rejected": _withCtx((cellProps) => [
                          _createVNode(_component_q_td, { props: cellProps }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_4, [
                                _createTextVNode(_toDisplayString(cellProps.value) + " ", 1),
                                (
                        cellProps.row.totalRejectedUsers > 0 &&
                        cellProps.row.rejectedUsers !== null
                      )
                                  ? (_openBlock(), _createBlock(_component_q_btn, {
                                      key: 0,
                                      rounded: "",
                                      dense: "",
                                      icon: "search",
                                      color: "primary",
                                      size: "sm",
                                      onClick: ($event: any) => (
                        openRejectedUsersDialog(cellProps.row.rejectedUsers)
                      )
                                    }, null, 8, ["onClick"]))
                                  : _createCommentVNode("", true)
                              ])
                            ]),
                            _: 2
                          }, 1032, ["props"])
                        ]),
                        "body-cell-status": _withCtx((cellProps) => [
                          _createVNode(_component_q_td, { props: cellProps }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, [
                                _createVNode(_component_q_badge, {
                                  color: _unref(getStatusColor)(cellProps.value),
                                  label: cellProps.value
                                }, null, 8, ["color", "label"])
                              ])
                            ]),
                            _: 2
                          }, 1032, ["props"])
                        ]),
                        _: 1
                      }, 8, ["rows", "columns"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_q_card, null, {
                default: _withCtx(() => [
                  (_ctx.productLine !== null && _unref(isConversionReportVisible))
                    ? (_openBlock(), _createBlock(ConversionReport, {
                        key: 0,
                        "data-test": "pm.card.conversion_report",
                        "message-id": _unref(formatMessageId),
                        "product-line": _ctx.productLine,
                        "message-type": _unref(MessageTypeEnum).pushNotification
                      }, null, 8, ["message-id", "product-line", "message-type"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ], 64))
          : _createCommentVNode("", true),
        _createVNode(_component_q_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_q_card_section, { class: "q-col-gutter-sm" }, {
              default: _withCtx(() => [
                _cache[18] || (_cache[18] = _createElementVNode("div", { class: "text-h6 q-mb-sm" }, "Message data", -1)),
                _createVNode(_component_q_input, {
                  "data-test": "pm.form.field.title",
                  disable: _unref(isDisabled) && !_ctx.isEditingActivePushMessage,
                  "model-value": _unref(pushMessage).title,
                  filled: "",
                  label: "Title",
                  hint: "Title of your message",
                  rules: [
              (val) =>
                (val && val.length > 0) || 'Message title cannot be empty',
            ],
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (
              updatePushMessage({
                title: $event ? String($event) : undefined,
              })
            ))
                }, null, 8, ["disable", "model-value", "rules"]),
                _createVNode(_component_q_input, {
                  "data-test": "pm.form.field.text",
                  disable: _unref(isDisabled) && !_ctx.isEditingActivePushMessage,
                  "model-value": _unref(pushMessage).text,
                  filled: "",
                  type: "textarea",
                  rows: "3",
                  label: "Text",
                  hint: "Text of your message",
                  rules: [
              (val) =>
                (val && val.length > 0) || 'Message text cannot be empty',
            ],
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (
              updatePushMessage({
                text: $event ? String($event) : undefined,
              })
            ))
                }, null, 8, ["disable", "model-value", "rules"]),
                _createVNode(_component_q_input, {
                  "data-test": "pm.form.field.url",
                  "model-value": _unref(pushMessage).url,
                  disable: _unref(isDisabled) && !_ctx.isEditingActivePushMessage,
                  filled: "",
                  label: "URL",
                  hint: "URL that your message redirects to. Use http://, https:// or deep link",
                  rules: [
              (val) =>
                val === null ||
                val === '' ||
                _unref(isValidMessageUrl)(val) ||
                'URL format is invalid',
            ],
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (
              updatePushMessage({
                url: $event ? String($event) : null,
              })
            ))
                }, null, 8, ["model-value", "disable", "rules"]),
                _createVNode(_component_q_input, {
                  type: "number",
                  "data-test": "pm.form.field.campaignid",
                  "model-value": _unref(pushMessage).campaignId,
                  disable: _unref(isDisabled) && !_ctx.isEditingActivePushMessage,
                  filled: "",
                  label: "Campaign ID",
                  hint: "ID of AMG campaign your push message will be assigned to.\n              Can be empty, in which case it is automatically populated each hour from the rid value of url.",
                  rules: [
              (val) =>
                val === null ||
                (Number.isInteger(Number(val)) && val >= 0) ||
                'Campaign ID must be a number higher or equal 0',
            ],
                  "onUpdate:modelValue": updateCampaignId
                }, null, 8, ["model-value", "disable", "rules"]),
                _createVNode(_component_q_card_section, { class: "row justify-end q-pt-none" }, {
                  default: _withCtx(() => [
                    (_unref(campaignAMGUrl) !== null)
                      ? (_openBlock(), _createBlock(_component_q_btn, {
                          key: 0,
                          "data-test": "pm.form.button.campaignid",
                          outline: "",
                          color: "primary",
                          size: "sm",
                          label: "Go to campaign page",
                          type: "a",
                          target: "_blank",
                          href: _unref(campaignAMGUrl)
                        }, null, 8, ["href"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                _createVNode(ImageSelect, {
                  "data-test": "pm.form.field.image",
                  "model-value": _unref(pushMessage).image,
                  "image-constraint-names": ['pushnotification'],
                  hint: "Image for your message",
                  label: "Image",
                  disable: _unref(isDisabled) && !_ctx.isEditingActivePushMessage,
                  "is-add-image-button-visible": _ctx.isAddImageButtonVisible,
                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (updatePushMessage({ image: $event })))
                }, null, 8, ["model-value", "disable", "is-add-image-button-visible"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_q_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_q_card_section, { class: "q-col-gutter-sm" }, {
              default: _withCtx(() => [
                _cache[19] || (_cache[19] = _createElementVNode("div", { class: "text-h6 q-mb-sm" }, "Delivery", -1)),
                _createVNode(_component_q_select, {
                  "data-test": "pm.form.field.delivery",
                  "model-value": _unref(selectedDeliveryType),
                  disable: _unref(isDisabled),
                  filled: "",
                  hint: "Type of delivery",
                  label: "Delivery type",
                  options: _unref(deliveryTypeOptions),
                  rules: [(val) => val !== null || 'Delivery type can\'t be null'],
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (_unref(updateDeliveryOptionsType)($event.value)))
                }, null, 8, ["model-value", "disable", "options", "rules"]),
                _createVNode(DeliveryOptions, {
                  "data-test": "pm.form.field.options",
                  "model-value": _unref(pushMessage).deliveryOptions,
                  "delivery-type": _unref(pushMessage).deliveryType,
                  disable: _unref(isDisabled),
                  "onUpdate:modelValue": _unref(updatePushMessageDeliveryOptions)
                }, null, 8, ["model-value", "delivery-type", "disable", "onUpdate:modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_ctx.productLine !== null)
          ? (_openBlock(), _createBlock(TargetCard, {
              key: 1,
              "data-test": "pm.form.field.target",
              "model-value": _unref(pushMessage).target,
              "product-line": _ctx.productLine,
              hint: "Target for your message",
              label: "Target",
              readonly: _unref(isDisabled),
              disabled: !_unref(isTargetEditingEnabled),
              "fetch-target": _unref(fetchMessageTarget),
              "fetch-targets": _unref(fetchTargets),
              "error-message": _unref(targetErrorMessage),
              "target-type": "pushnotifications",
              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => (updatePushMessage({ target: $event })))
            }, null, 8, ["model-value", "product-line", "readonly", "disabled", "fetch-target", "fetch-targets", "error-message"]))
          : _createCommentVNode("", true),
        (!isAdvancedOptionsVisible.value)
          ? (_openBlock(), _createBlock(_component_q_btn, {
              key: 2,
              "data-test": "pm.form.button.advancedfilters",
              size: "sm",
              onClick: _cache[15] || (_cache[15] = ($event: any) => (isAdvancedOptionsVisible.value = true))
            }, {
              default: _withCtx(() => _cache[20] || (_cache[20] = [
                _createTextVNode("Show advanced options")
              ])),
              _: 1
            }))
          : _createCommentVNode("", true),
        (isAdvancedOptionsVisible.value)
          ? (_openBlock(), _createBlock(_component_q_card, { key: 3 }, {
              default: _withCtx(() => [
                _createVNode(_component_q_card_section, { class: "q-y-gutter-sm" }, {
                  default: _withCtx(() => [
                    _cache[21] || (_cache[21] = _createElementVNode("div", { class: "text-h6" }, "Advanced options", -1)),
                    _createVNode(_component_q_checkbox, {
                      "data-test": "pm.form.button.dryrun",
                      disable: _unref(isDisabled),
                      "model-value": _unref(pushMessage).isDryRun,
                      label: "Message dry run",
                      "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => (updatePushMessage({ isDryRun: $event })))
                    }, null, 8, ["disable", "model-value"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 512)
  ]))
}
}

})