import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { id: "chart" }

import { computed, toRefs } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'RejectedUsersDialog',
  props: {
    rejectedPerMessage: {}
  },
  setup(__props: any) {

const props = __props;

const { rejectedPerMessage } = toRefs(props);

const labels = computed(() => {
  return Object.keys(rejectedPerMessage.value);
});

const series = computed(() => {
  return Object.values(rejectedPerMessage.value);
});

const chartOptions = computed(() => {
  return {
    chart: {
      type: "polarArea",
    },
    stroke: {
      colors: ["#fff"],
    },
    fill: {
      opacity: 0.8,
    },
    labels: labels.value,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
});

return (_ctx: any,_cache: any) => {
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_bar = _resolveComponent("q-bar")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_apexchart = _resolveComponent("apexchart")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_dialog, { ref: "dialogRef" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_q_bar, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_space),
              _withDirectives((_openBlock(), _createBlock(_component_q_btn, {
                dense: "",
                flat: "",
                icon: "close"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_tooltip, null, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createTextVNode("Close")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })), [
                [_directive_close_popup]
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createElementVNode("div", { class: "text-h6" }, "Rejected users (per message ID)", -1)
            ])),
            _: 1
          }),
          _createVNode(_component_q_card_section, { class: "q-pt-none" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_apexchart, {
                  type: "polarArea",
                  options: chartOptions.value,
                  series: series.value,
                  labels: labels.value
                }, null, 8, ["options", "series", "labels"])
              ]),
              _cache[2] || (_cache[2] = _createElementVNode("p", null, " ID of the message indicates the first message that consumed that user. ", -1))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 512))
}
}

})